import { openNotificationWithIcon } from "@/utils";
import Index from "../index";
import router from "@/router";
import { api, MAP_API_KEY } from "@/.config";
import axios from "axios";

interface Auth {
	email: string;
	password: any;
}

export async function loginApi(data: object) {
	return await Index.post<Auth>(`${api.base}user/login`, data)
		.then((res) => res)
		.catch((error) => {
			openNotificationWithIcon("error", "server is not responding yet");
			throw error;
		});
}
export async function loginWithFacebookAPI(data: object) {
	return await Index.post<Auth>(`${api.base}user/social-login-with-facebook`, data)
		.then((res) => res)
		.catch((error) => {
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
}

export async function LoginWithGoogleAPI(data: object) {
	return await Index.post<Auth>(`${api.base}user/social-login-with-google`, data)
		.then((res) => res)
		.catch((error) => {
			console.log("error", error?.response?.data?.message);
		});
}

export async function LoginWithAppleAPI(data: object) {
	return await Index.post<Auth>(`${api.base}user/social-login-with-apple`, data)
		.then((res) => res)
		.catch((error) => {
			console.log("error", error?.response?.data?.message);
		});
}

export const deletedUserApi = async (userID: any, adminUserID: any) => {
	return await Index.delete<Auth>(`${api.base}user/delete/${userID}/${adminUserID}`)
		.then((res) => res)
		.catch((error: any) => {
			openNotificationWithIcon("error", error?.response?.data?.message);
		});
};


export const userLoginCheck = async () => {
	try {
		let user: any = localStorage.getItem("user");
		if (!user) {
			router.push("/login");
			return false;
		}
		user = JSON.parse(user);
		if (user?.adminLoginTime) {
			return true;
		}
	} catch (error) {
		router.push("/login");
		return false;
	}
};
export async function updateUserApi(id: any, data: object, isDelete: boolean = false) {
	return await Index.put<Auth>(`${api.base}user/edit/${id}`, data)
		.then((res) => {
			res;
			if (isDelete) {
				openNotificationWithIcon("success", "User has been deleted successfully");
			}
			else {
				openNotificationWithIcon("success", res?.message);
			}
		})
		.catch((error) => {
			openNotificationWithIcon("error", error?.response?.data?.msg);
		});
}

export async function addUserApi(data: object) {
	return await Index.post<Auth>(`${api.base}user/create`, data).then((res) => {
		if (!res?.valid) {
			openNotificationWithIcon("error", res?.message);
		}
		else {
			openNotificationWithIcon("success", res?.message);
			return res;
		}
	}).catch((error) => {
		openNotificationWithIcon("error", error?.response.data.message);
		throw error
	});
}

export async function getLocationApi(data: string) {
	return await axios.get(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${data}&key=${MAP_API_KEY}`)
}

export async function getGeoCordApi(place: string) {
	return await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(place)}&key=${MAP_API_KEY}`)
}

export async function saveUserChart(data: object) {
	return await Index.post<Auth>(`${api.base}astrologic/save-member-chart`, data)
		.then((res) => {
			res;
			openNotificationWithIcon("success", res?.message || 'saved successfully');
		})
		.catch((error) => {
			openNotificationWithIcon("error", error?.response?.data?.msg);
		});
}
