<template>
	<div class="styleDiv">
		<a-form :model="formState" class="form">
			<div>
				<img src="../assets/logo.png" alt="Vue logo" class="image" />
				<div style="text-align: center; margin: 20px; font-size: 22px; color: #6d2144; font-weight: 600; font-family: sans-serif; letter-spacing: 0.3px">LOGIN</div>
			</div>
			<a-form-item class="formItem">
				<a-input class="input" v-model:value="formState.email" placeholder="Email" />
			</a-form-item>
			<a-form-item class="formItem" name="password">
				<a-input-password placeholder="Password" v-model:value="formState.password" />
			</a-form-item>

			<a-button type="primary" size="large" class="btn" @click="onSubmit">Login</a-button>

			<div>
				<div class="mainDiv">
					<div class="subDiv">
						<span :style="{ margin: '0px 5px' }">Or sign in with</span>
					</div>
				</div>
				<div class="socialMediaLogoContainer">
					<div>
						<button type="button" class="button_container">
							<GoogleLogin :callback="loginWithGoogle">
								<img src="../../public/google.png" alt="Login with google" style="width: 46px; height: 46px" />
							</GoogleLogin>
						</button>
					</div>
					<div>
						<button @click="loginWithFacebook" type="button" class="button_container">
							<img src="../../public/sm_facebook.png" alt="Login with Facebook" style="width: 46px; height: 46px" />
						</button>
					</div>
					<div>
						<button @click="loginWithApple" type="button" class="button_container">
							<img src="../../public/apple.png" alt="Login with Apple" style="width: 46px; height: 46px" />
						</button>
					</div>
				</div>
			</div>
		</a-form>
	</div>
</template>

<script lang="ts" setup>
	import { loginApi, LoginWithAppleAPI, LoginWithGoogleAPI, loginWithFacebookAPI } from "@/services/api/auth";
	import { UnwrapRef, reactive } from "vue";
	import { openNotificationWithIcon } from "../utils/index";
	import router from "@/router";
	import { jwtDecode } from "jwt-decode";
	import { APPLE_CLIENT_ID, APPLE_REDIRECT_URI } from "../.config";
	interface FormState {
		email: string;
		password: string;
	}

	const formState: UnwrapRef<FormState> = reactive({
		email: "",
		password: "",
	});

	const onSubmit = async () => {
		try {
			if (!formState.email) {
				return openNotificationWithIcon("warning", "Please enter email addres");
			}
			if (!formState.password) {
				return openNotificationWithIcon("warning", "Please enter password");
			} else {
				const apiRes: any = await loginApi(formState);
				if (!apiRes) {
					return openNotificationWithIcon("error", "Invalid email or password");
				}
				if (apiRes.msg && !apiRes.valid) {
					return openNotificationWithIcon("warning", apiRes.msg);
				}
				if (!apiRes.roles || !apiRes.roles.includes("active")) {
					return openNotificationWithIcon("warning", "You don't have permission to access website");
				}
				apiRes.adminLoginTime = new Date().getTime();
				localStorage.setItem("user", JSON.stringify(apiRes));
				openNotificationWithIcon("success", "Login successful.");
				router.push("/delete-account");
			}
		} catch (error) {
			openNotificationWithIcon("error", "Something went wrong. Please try again later.");
		}
	};

	const loginWithFacebook = () => {
		window.FB.login(
			(response: any) => {
				if (response.authResponse) {
					window.FB.api("/me", { fields: "name,email" }, async (response: any) => {
						try {
							const apiRes: any = await loginWithFacebookAPI({
								identifier: response.email,
								socialId: response.id,
							});

							if (apiRes && apiRes.success && apiRes.user) {
								localStorage.setItem("user", JSON.stringify(apiRes.user));
								openNotificationWithIcon("success", "Login successful.");
								router.push("/delete-account");
							}
						} catch (error: any) {
							console.error("Error in login:", error);
						}
					});
				}
			},
			{ scope: "public_profile,email" }
		);
	};

	const loginWithGoogle = async (response: any) => {
		try {
			const code = response.code;
			if (!code) {
				console.error("No authorization code returned");
				return;
			}
			const apiRes: any = await LoginWithGoogleAPI({
				code: code,
			});

			if (apiRes && apiRes.success && apiRes.user) {
				localStorage.setItem("user", JSON.stringify(apiRes.user));
				openNotificationWithIcon("success", "Login successful.");
				router.push("/delete-account");
			} else {
				openNotificationWithIcon("error", "User Not Found");
			}
		} catch (error) {
			console.error("Error during Google login:", error);
			openNotificationWithIcon("error", "Something went wrong. Please try again.");
		}
	};

	const loginWithApple = async () => {
		try {
			window.AppleID.auth.init({
				clientId: APPLE_CLIENT_ID,
				scope: "name email",
				redirectURI: APPLE_REDIRECT_URI,
				usePopup: true,
			});
			const response = await window.AppleID.auth.signIn();
			const {
				authorization: { id_token },
			} = response;

			const decoded: any = jwtDecode(id_token);
			const email = decoded.email;
			const socialId = decoded.sub;
			const apiRes = await LoginWithAppleAPI({
				identifier: email,
				socialId: socialId,
			});

			if (apiRes && apiRes.success && apiRes.user) {
				localStorage.setItem("user", JSON.stringify(apiRes.user));
				openNotificationWithIcon("success", "Login successful.");
				router.push("/delete-account");
			} else {
				openNotificationWithIcon("error", "Login failed.");
			}
		} catch (error) {
			console.error("Apple Login Error", error);
		}
	};
</script>

<style scoped>
	.styleDiv {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.form {
		background-color: #fff;
		width: 360px;
		padding: 1rem 2rem;
		box-shadow: 0 10px 25px rgba(92, 99, 105, 0.2);
		.ant-form-item-label {
			position: relative;
			height: 48px;
		}
	}

	.input {
		text-align: left;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 1px solid #dadce0;
		outline: none;
		padding: 1rem;
		background: none;
		z-index: 1;
	}

	.image {
		width: 70%;
		height: auto;
		margin: auto;
		display: block;
	}

	.formItem {
		position: relative;
		height: 48px;
	}

	.btn {
		width: 100%;
		letter-spacing: 0.3px;
	}

	.zm-login-methods__title {
		position: relative;
		text-align: center;
		margin-top: 12px;
		margin-bottom: 24px;
	}

	.button_container {
		border: none;
		background: none;
		padding: 0;
		cursor: pointer;
		margin-bottom: 20px;
	}

	.zm-login-methods__title span {
		background-color: #fff;
		padding-left: 12px;
		padding-right: 12px;
		display: inline-block;
		line-height: 24px;
		position: relative;
		z-index: 1;
		color: #747487;
	}

	.mainDiv {
		border-top: 1px solid rgb(190, 190, 190);
		position: relative;
		margin: 25px 0px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.subDiv {
		display: flex;
		position: absolute;
		top: -11px;
		background: #fff;
		margin-left: auto;
		margin-right: auto;
		padding: 0 5px;
		font-size: 15px;
		justify-content: center;
		align-items: center;
		color: rgb(190, 190, 190);
	}

	.socialMediaLogoContainer {
		display: flex;
		gap: 10px;
		justify-content: center;
	}
</style>
