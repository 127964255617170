import { createApp } from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import "ant-design-vue/dist/reset.css";
import router from "./router";
import Index from "./services";
import "./style.css";
import { GOOGLE_CLIENT_ID } from "@/.config";
import vue3GoogleLogin from 'vue3-google-login'

Index.init();

const app = createApp(App);
app.use(router);
app.use(vue3GoogleLogin, {
    clientId: GOOGLE_CLIENT_ID,
})
app.use(Antd).mount("#app");
