<template>
	<a-config-provider
		style="min-height: 100vh"
		:theme="{
			token: {
				colorPrimary: '#44142a',
				colorPrimaryBg: '#fcdfec',
			},
		}"
	>
		<router-view />
	</a-config-provider>
</template>

<script lang="ts" setup>
	import { onMounted } from "vue";
	import { Facebook_APP_ID } from "@/.config";
	import { WindowsFilled } from "@ant-design/icons-vue";

	declare global {
		interface Window {
			FB: any;
			google: any;
			AppleID: any; //
		}
	}

	onMounted(() => {
		if (window.FB) {
			window.FB.init({
				appId: Facebook_APP_ID,
				status: true,
				cookie: true,
				xfbml: true,
				version: "v10.0",
			});
		} else {
			console.error("Facebook SDK not loaded");
		}
	});
</script>

<style scoped>
	#components-layout-demo-side .logo {
		height: 32px;
		margin: 16px;
		background: rgba(255, 255, 255, 0.3);
	}
	.imgLogo {
		width: 100%;
	}

	.site-layout .site-layout-background {
		background: #fff;
	}
</style>

<style scoped>
	#components-layout-demo-side .logo {
		height: 32px;
		margin: 16px;
		background: rgba(255, 255, 255, 0.3);
	}
	.imgLogo {
		width: 100%;
	}

	.site-layout .site-layout-background {
		background: #fff;
	}
</style>
